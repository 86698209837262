import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgUserGradientSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 24, height: 24, fill: "none", "data-icon": "usergradientsolid", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { fill: "url(#paint0_linear_1795_113659)", fillOpacity: 0.9, d: "M6 5.4a1.8 1.8 0 1 0 0-3.6 1.8 1.8 0 0 0 0 3.6ZM1.8 10.8a4.2 4.2 0 0 1 8.4 0H1.8Z" }),
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsxs("linearGradient", { id: "paint0_linear_1795_113659", x1: 6, x2: 6, y1: 1.8, y2: 10.8, gradientUnits: "userSpaceOnUse", children: [
    /* @__PURE__ */ jsx("stop", { stopColor: "var(--svg-stop-color-0,#849BE5)" }),
    /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "var(--svg-stop-color-1,#3D61DD)" })
  ] }) })
] });
const ForwardRef = forwardRef(SvgUserGradientSolid);
export default ForwardRef;
ForwardRef.displayName = "UserGradientSolidIcon";
