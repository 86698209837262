import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgTaskGradientSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 24, height: 24, fill: "none", "data-icon": "taskgradientsolid", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { stroke: "url(#paint0_linear_2396_111908)", strokeLinecap: "round", strokeLinejoin: "round", strokeOpacity: 0.9, strokeWidth: 1.75, d: "m2.5 6.5 2 2 5-5" }),
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsxs("linearGradient", { id: "paint0_linear_2396_111908", x1: 6, x2: 6, y1: 3.5, y2: 8.5, gradientUnits: "userSpaceOnUse", children: [
    /* @__PURE__ */ jsx("stop", { stopColor: "var(--svg-stop-color-0,#849BE5)" }),
    /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "var(--svg-stop-color-1,#3D61DD)" })
  ] }) })
] });
const ForwardRef = forwardRef(SvgTaskGradientSolid);
export default ForwardRef;
ForwardRef.displayName = "TaskGradientSolidIcon";
