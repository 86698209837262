import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgUsersGradientSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 24, height: 24, fill: "none", "data-icon": "usersgradientsolid", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { fill: "url(#paint0_linear_1851_24273)", fillOpacity: 0.9, d: "M5.4 3.6a1.8 1.8 0 1 1-3.6 0 1.8 1.8 0 0 1 3.6 0ZM10.2 3.6a1.8 1.8 0 1 1-3.6 0 1.8 1.8 0 0 1 3.6 0ZM7.758 10.2A4.182 4.182 0 0 0 6.9 7 3 3 0 0 1 11.4 9.6v.6H7.758ZM3.6 6.6a3 3 0 0 1 3 3v.6h-6v-.6a3 3 0 0 1 3-3Z" }),
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsxs("linearGradient", { id: "paint0_linear_1851_24273", x1: 6, x2: 6, y1: 1.8, y2: 10.2, gradientUnits: "userSpaceOnUse", children: [
    /* @__PURE__ */ jsx("stop", { stopColor: "var(--svg-stop-color-0,#849BE5)" }),
    /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "var(--svg-stop-color-1,#3D61DD)" })
  ] }) })
] });
const ForwardRef = forwardRef(SvgUsersGradientSolid);
export default ForwardRef;
ForwardRef.displayName = "UsersGradientSolidIcon";
